import { React, useEffect, useRef, useState } from 'react'
import emailjs from "@emailjs/browser";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import './contact.css';

gsap.registerPlugin(ScrollTrigger);



export default function Contact({updateNavFunc}) {



  const contact = useRef();

  useEffect(() => {

    let ctx = gsap.context((self) => {

      let offset = window.innerHeight;

      let mm = gsap.matchMedia();
      let inTL;
      let outTL;

      gsap.delayedCall(0.1, function () {


        let navTLInTop = gsap.timeline({
          defaults:{duration:1, ease:"none"},
          scrollTrigger: {
            markers:false,
            trigger: contact.current,
            start: 'top 50%',
            end:'top 50%',
            scrub: false,
            onUpdate: self => {
              if (self.direction == 1)
              {
                updateNavFunc("Contact");
              }
            }
          }
        });

        let navTLInBottom = gsap.timeline({
          defaults:{duration:1, ease:"none"},
          scrollTrigger: {
            markers:false,
            trigger: contact.current,
            start: 'bottom 50%',
            end:'bottom 50%',
            scrub: false,
            onUpdate: self => {
              if (self.direction == -1)
              {
                updateNavFunc("Contact");
              }
            }
          }
        });

        mm.add("(min-width: 100px)", () => {
          inTL = gsap.timeline({
            defaults: { duration: 1, ease: "none" },
            scrollTrigger: {
              markers: false,
              trigger: contact.current,
              start: 'top 50%',
              end: 'top 5%',
              scrub: true
            }
          });

          // inTL.from(self.selector(".circle"), { rotateX:90, duration: 1 }, 0);
          // inTL.to(self.selector(".fader"), { opacity: 0 }, 0);
          inTL.from(self.selector(".form-wrapper"), 1, {y:100}, 0);


          outTL = gsap.timeline({
            defaults: { duration: 1, ease: "none" },
            scrollTrigger: {
              markers: false,
              trigger: contact.current,
              start: 'bottom 100%',
              end: 'bottom 0%',
              scrub: true
            }
          });
          // outTL.to(clients.current, { y: "50vh" }, 0);
          // outTL.to(self.selector(".fader"), { opacity: 1 }, 0);
        });
      });
    }, contact);

    return () => ctx.revert();

  }, []);

  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .send(
        "service_qjcki6d",
        "template_f0l5f3r",
        {
          from_name: form.name,
          to_name: "Adam Cousins",
          from_email: form.email,
          to_email: "cousins.aj@gmail.com",
          message: form.message,
        },
        "z0QrmUgwP0noVYx0H"
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");

          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  return (
    <div ref={contact} className='contact-wrapper container' id="contact">
      {/* <div className='fader'></div> */}
      
      <h1>Let's <span>work together</span></h1>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className='form-wrapper'
      >
        <label className='form-label'>
          {/* <span className=''>Your Name</span> */}
          <input
            type='text'
            name='name'
            value={form.name}
            onChange={handleChange}
            placeholder="What's your name?"
            className=''
          />
        </label>
        <label className='form-label'>
          {/* <span className=''>Your Email</span> */}
          <input
            type='text'
            name='email'
            value={form.email}
            onChange={handleChange}
            placeholder="What's your email?"
            className=''
          />
        </label>
        <label className='form-label long'>
          {/* <span className=''>Your Message</span> */}
          <textarea
            rows={7}
            name='message'
            value={form.message}
            onChange={handleChange}
            placeholder='Write me a message...'
            className=''
          />
        </label>
        <button
          type='submit'
          className='form-button'
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  )
}

