import { React, useLayoutEffect, useEffect, useRef } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import AboutCarousel from './components/carousel/AboutCarousel';
import './about.css';

gsap.registerPlugin(ScrollTrigger);

export default function About({updateNavFunc}) {

  const about = useRef();


  useEffect(() => {
    //console.log("USE LAYOUT EFFECT")
    let ctx = gsap.context((self) => {


      let mm = gsap.matchMedia();
      let inTL;
      let outTL;

    
      
      gsap.delayedCall(0.1, function() {

        mm.add("(min-width: 50px)", () => {



          let navTLInTop = gsap.timeline({
            defaults:{duration:1, ease:"none"},
            scrollTrigger: {
              markers:false,
              trigger: about.current,
              start: 'top 50%',
              end:'top 50%',
              scrub: false,
              onUpdate: self => {
                if (self.direction == 1)
                {
                  updateNavFunc("Experience");
                }
              }
            }
          });

          let navTLInBottom = gsap.timeline({
            defaults:{duration:1, ease:"none"},
            scrollTrigger: {
              markers:false,
              trigger: about.current,
              start: 'bottom 50%',
              end:'bottom 50%',
              scrub: false,
              onUpdate: self => {
                if (self.direction == -1)
                {
                  updateNavFunc("Experience");
                }
              }
            }
          });


          inTL = gsap.timeline({
            defaults:{duration:1, ease:"none"},
            scrollTrigger: {
              markers:false,
              trigger: about.current,
              start: 'top 50%',
              end:'top 1%',
              scrub: true
            }
          });


          inTL.from(self.selector(".about-clip-wrapper"), {yPercent:-25}, 0.5);
          inTL.from(self.selector(".about-header"), {opacity:0, x:-300}, 0.25);
          inTL.from(self.selector(".about-copy"), {opacity:0, x:-300}, 0.5);
          inTL.from(self.selector(".circle-small"), {scale:1.5, opacity:0}, 0);
          // inTL.to(self.selector(".fader"), {opacity:0}, 0);
    
    
          outTL = gsap.timeline({
            defaults:{duration:1, ease:"none"},
            scrollTrigger: {
              markers:false,
              trigger: about.current,
              start: 'bottom 99%',
              end:'bottom 1%',
              scrub: true
            }
          });
          outTL.to(self.selector(".circle-small"), {scale:0}, 0);
          outTL.to(self.selector(".carousel-root"), {y:"50vh"}, 0);
        });
      });


    }, about); 

    return () => ctx.revert();
      
  }, []);

  return (
    <div ref={about} className='about-wrapper container' id="about">
      {/* <div className='fader'></div> */}
      <div className='container-inner'>
        <div className="centered circle-small"></div>
      </div>
      
      <AboutCarousel />
    </div>
  )
}

