import React from 'react';


import '../workpage.css'


export default function IntroBlockComponent({ data }) {


    return (
        <div className='work-item-section text-block'>
            <h2>The brief</h2>
            <p>{data.brief}</p><br />
            <h2>My response</h2>
            <p>{data.response}</p>
        </div>
    );


};

