export const navLinks = [
  {
    id: "#home",
    title: "Home",
  },
  {
    id: "#about",
    title: "Experience",
  },
  {
    id: "#work",
    title: "Work",
  },
  {
    id: "#testimonials",
    title: "Testimonials",
  },
  {
    id: "#contact",
    title: "Contact",
  },
  {
    id: "",
    title: "Showreel",
  }
];



export const workData = [

  {
    name: 'richscroller',
    client: 'SeenThis / Various Brands',
    title: 'Rich Scroller high-impact ad format',
    subtitle: 'Responsive front-end creative build',
    agency: 'Created at SeenThis',
    heroMockup: true,
    content: [
      {
        type: "intro",
        brief: "Lotus wanted to replicate the premium feel from the landing page of the new Electre electric sports car, but inside rich IAB ads to maximise scale",
        response: "Working alongside SeenThis Creative Director, I developed from scratch the 'Rich Scroller' proprietry format, allowing multiple sections to be scrolled through on Mobile and Desktop, with rich, premium content and animation synced up the user scroll, much like a high-end product landing page. The ads performed so well Lotus had to pause the campaign as they could not keep up with the number of test drive bookings."
      },
      { type: "inlinevideo", path: "dmpu-lotus.mp4" },
      { type: "inlinevideo", path: "dmpu-tesco.mp4" },
      { type: "url", path: "https://play.seenthis.co/preview/cH0slli1o", caption: "See live previews" },
      { type: "gallery", length: 2 }
    ]
  },


  {
    name: 'ultraboost',
    client: 'Adidas',
    title: 'Ultraboost\n+ Solarglide',
    subtitle: 'Resonsive design and build',
    agency: 'Created at Justpremium',
    heroMockup: false,
    heroFile: "hero.png",
    content: [
      {
        type: "intro",
        brief: "I was asked by JustPremium to produce a range of digital creative solutions utlising Adidas’s campaign design collateral for the launch of their new Ultraboost and Solarglide 5 shoes, with awareness and interactition time as the main KPIs.",
        response: "I produced concepts and designs for a responsive home page takeover and mobile display ad creatives, as well as a motion graphics video created in After Effects and a concept for a responsive landing page. I was invited to pitch the rich media creatives to Adidas DE which led to us winnig the paid digital campaign work."
      },
      { type: "inlinevideo", path: "video.mp4" },
      { type: "url", path: "https://adidas-ultraboost-demo.herokuapp.com/", caption: "See the website" },
      { type: "gallery", length: 5 },
    ]
  },
  {
    name: 'believer',
    client: 'Interscope',
    title: 'Imagine Dragons - Believer',
    subtitle: 'High impact creative build',
    agency: 'Created at Undertone',
    heroMockup: true,
    content: [
      { type: "gallery", length: 2 }
    ]
  },

  {
    name: 'javascriptgames',
    client: 'Various Clients',
    title: 'HTML5 + Javascript 2D Games',
    subtitle: 'Various 2D game builds created using Pixi.js',
    agency: 'Created at Justpremium',
    heroMockup: true,
    content: [
      { type: "video", embedId: "7yIa1-Ve5bE" },
      { type: "gallery", length: 4 }
    ]
  },

  {
    name: 'nightjogger',
    client: 'Adidas',
    title: 'Night Jogger',
    subtitle: 'Design and creative build',
    agency: 'Created at Justpremium',
    heroMockup: false,
    heroFile: "hero.png",
    content: [
      { type: "gallery", length: 2 }
    ]
  },


  {
    name: 'hendricks',
    client: 'Hendricks',
    title: 'Hendricks Gin',
    subtitle: 'Motion design and creative build',
    agency: 'Created at Justpremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 2 }
    ]
  },


  {
    name: 'mercedes',
    title: 'Mercedes\nA-Klasse',
    subtitle: 'Home-page takeover creative build',
    agency: 'Created at Justpremium',
    client: 'Mercedes',
    heroMockup: true,
    content: [
      { type: "gallery", length: 3 }
    ]
  },

  {
    name: 'mindstorm',
    client: 'Mindstorm',
    title: 'Multi-touch 3D Games',
    subtitle: 'Bespoke touch-screen Unity3D games dev',
    agency: 'Created at Mindstorm',
    heroMockup: false,
    heroFile: "hero.png",
    content: [
      { type: "video", embedId: "L1ZKySw1QyA" },
      { type: "video", embedId: "1EVRfUVVEo4" },
      { type: "gallery", length: 4 }
    ]
  },

  {
    name: 'hondahrv',
    client: 'Honda',
    title: 'All New HR-V',
    subtitle: 'Home-page takeover creative build',
    agency: 'Created at Justpremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 2 }
    ]
  },

  {
    name: 'disneyplus',
    client: 'Disney',
    title: 'Disney+ Campaigns',
    subtitle: 'Home-page takeovers',
    agency: 'Created at Justpremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 3 }
    ]
  },


  {
    name: 'sleepbuds',
    client: 'Bose',
    title: 'Sleep Buds',
    subtitle: 'Design and creative build, responsive formats',
    agency: 'Created at Justpremium',
    heroMockup: true,
    content: [
      {
        type: "intro",
        brief: "JustPremium asked me come up with a concept and lead the design work for the digital campaign for the new Bose Sleepbuds in-ear headphones. Bose asked us to repurpse their OOH campaign visuals into a compelling set of digital creatives, with interactivity and product awareness as the KPIs, whilst mainting Bose’s already strong branding.",
        response: "Working with a large bank of client assets, I concentrated the key elements into a set of interactive digital ad creatives, utilising both video and campaign imagery content. There is a simple story being told as the user navigates through the creative, with a clear resolution at the end for the target audience pain point of distrupted sleep tied directly to the product."
      },
      { type: "gallery", length: 4 }
    ]
  },

  {
    name: 'canadadragrace',
    client: 'BBC',
    title: 'Canada\nDrag Race',
    subtitle: 'Home-page takeover creative design and build',
    agency: 'Created at Justpremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 5 }
    ]
  },


  {
    name: 'netflix',
    client: 'Netflix',
    title: 'Rich Media formats pitch storyboard',
    subtitle: 'Responsive UI/UX design',
    agency: 'Created at JustPremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 2 }
    ]
  },

  {
    name: 'revit',
    client: 'Rev-It',
    title: 'The DIRT Series\nCollection',
    subtitle: 'Design - Rich Media Display Creatives',
    agency: 'Created at Justpremium',
    heroMockup: false,
    heroFile: "hero.png",
    content: [
      {
        type: "intro",
        brief: "JustPremium asked me to lead the design work for the Motorcycle clothing designers Rev-It brief. They asked us to utilise their catalogue of lifestyle photography images and campaign video for their ‘DIRT’ Collection and come up with a pitch to drive brand awareness and click throughs to their main website.",
        response: "I produced concepts which drew upon their typically clean aesthetics with a strong grid and typography, making heavy use of clips edited from their campaign video which worked well as backdrops, letting the still product shots really stand out, with an overall rugged vibe offset with clean lines and animations."
      },
      { type: "gallery", length: 4 }
    ]
  },

  {
    name: 'habitat',
    client: 'Habitat',
    title: 'Refresh Your Home',
    subtitle: 'Design - Rich Media Display Creatives',
    agency: 'Created at GumGum',
    heroMockup: true,
    content: [
      {
        type: "intro",
        brief: "I was asked by GumGum to conceptualise and design cross-device rich media display ad creatives for Habitats 'Refresh your Home' integrated campaign, with a focus on interactivity to promote high engagement.",
        response: "I produced concepts and designs for responsive interactive ads for both desktop and mobile, featuring a Habitat 'scene' which the user can pan around and hover over the products hotspots to learn more about each item."
      },
      { type: "gallery", length: 3 }
    ]
  },

  {
    name: 'instagram',
    client: 'Self Employed',
    title: 'Photoshop artwork',
    subtitle: 'Creative re-touching and compositing | Instagram 22k+',
    agency: '',
    heroMockup: false,
    heroFile: "cover.jpg",
    content: [
      {
        type: "text",
        text: "Since early 2020, I have been producing photo-manipulation and compositing artwork in Photoshop, posting regularly to my Instagram @adamjcuz and have built up a following of over 22k followers.\n\nI have been featured in several global art magazines as well as the official Adobe Photoshop Instagram account, created tutorial courses for PhotoshopEasy.com and have generated over 4k physical sales of my artwork across Dispate and Etsy.com."
      },
      { type: "gallery", length: 8 }
    ]
  },



  {
    name: 'sonos',
    client: 'Sonos',
    title: 'Sonos Roam',
    subtitle: 'UI Storyboard pitch',
    agency: 'Created at Justpremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 6 }
    ]
  },

  {
    name: 'nike',
    client: 'Nike',
    title: '25% Off Campaign',
    subtitle: 'Design and motion, responsive display creatives',
    agency: 'Created at GumGum',
    heroMockup: true,
    content: [
      { type: "gallery", length: 4 }
    ]
  },




  {
    name: 'skykids',
    client: 'Sky',
    title: 'Sky Kids',
    subtitle: 'Home-page takeover',
    agency: 'Created at Justpremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 2 }
    ]
  },
  {
    name: 'lvmh',
    client: 'LVMH',
    title: 'Covid Concept Pitch',
    subtitle: 'Multi-channel',
    agency: 'Created at Justpremium',
    heroMockup: false,
    heroFile: "hero.png",
    content: [
      { type: "gallery", length: 2 }
    ]
  },
  // {
  //   name: 'ghost',
  //   client: 'Sony PlayStation',
  //   title: 'Ghost of Tsushima',
  //   subtitle: 'Design and motion, Digital display creatives',
  //   agency: 'Created at Justpremium',
  //   heroMockup: true,
  //   content: [
  //     { type: "gallery", length: 7 }
  //   ]
  // },
  {
    name: 'predator',
    client: 'Adidas',
    title: 'Predator',
    subtitle: 'Rich Media Storyboard pitch',
    agency: 'Created at JustPremium',
    heroMockup: false,
    heroFile: "hero.png",
    content: [
      { type: "gallery", length: 3 }
    ]
  },
  {
    name: 'swr',
    client: 'South Western Rail',
    title: 'South Western Rail',
    subtitle: 'Digital display creatives',
    agency: 'Created at JustPremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 4 }
    ]
  },
  {
    name: 'wordtracky',
    client: 'Self Employed',
    title: 'iOS & Android Games',
    subtitle: "'Portal Frog' & 'Word Tracky' games",
    agency: '',
    heroMockup: false,
    heroFile: "hero.png",
    content: [
      { type: "video", embedId: 'XIp54IYndPM' },
      { type: "video", embedId: 'hV-U9cdv5-8' },
      { type: "gallery", length: 4 }
    ]
  },

  {
    name: 'outriders',
    client: 'Square Enix',
    title: 'Outriders',
    subtitle: 'UI Storyboard pitch',
    agency: 'Created at JustPremium',
    heroMockup: true,
    content: [
      { type: "gallery", length: 3 }
    ]
  },
  {
    name: 'ifyoucan',
    client: 'If You Can',
    title: "'If...' iPad Game",
    subtitle: '3D educational adventure game',
    agency: 'Gameplay developer at If You Can',
    heroMockup: false,
    heroFile: "hero.png",
    content: [
      { type: "gallery", length: 2 }
    ]
  }
];