import { React, useLayoutEffect, useRef, useState } from 'react'
import { BrowserRouter } from "react-router-dom";
import { gsap } from "gsap";

import { Navbar, NavStrip } from '../../components';
import { Hero, About, Work, Testamonials, Contact, Clients } from '../../containers';


import './homepage.css';

const HomePage = () => {

  let home = useRef();
  let nav = useRef();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    window.dispatchEvent(new Event('resize'));

    let ctx = gsap.context((self) => {

    }, home);

    return function () {
      ctx.revert();
    }
  }, []);

  function handleUpdateNav(to) {
    nav.current.updateNavFromParent(to);
  }

  return (
    <div ref={home} className='page home-wrapper'>
      <Navbar />
      <NavStrip ref={nav}  />
      <Hero updateNavFunc={handleUpdateNav} />
      <About updateNavFunc={handleUpdateNav} />
      
      <Work updateNavFunc={handleUpdateNav} />
      <Testamonials updateNavFunc={handleUpdateNav} />
      <Clients updateNavFunc={handleUpdateNav} />
      <Contact updateNavFunc={handleUpdateNav} />
    </div>
  )
}

export default HomePage