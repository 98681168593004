import React from 'react';


import '../workpage.css'


export default function TextBlockComponent({ data }) {


    return (
        <div className='work-item-section text-block'>
            <p>{data.text}</p><br />
        </div>
    );


};

