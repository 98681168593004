import React from 'react';
import { gsap } from "gsap";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './work-carousel.css';
import Work from '../../Work';

const ImageSlide = ({path}) => {

    return (
      <>
        <div className="work-slide">
            <div className='slide-inner'>
                <img src={path} className='gallery-img' />  
            </div>
        </div>
      </>
    )
}

export default function CarouselCompnent({ workName, length }) {

    let srcArray = [];
    for (var i = 0; i < length; i++) {
        let src = '/workdata/' + workName + '/' + i + '.jpg';
        srcArray.push(src);
    }

    return (

        <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
        >
            {srcArray.map((item, index) => (   
                <ImageSlide path={item} key={index} />
            ))}
        </Carousel>
    );
};
