import React from 'react';
import { gsap } from "gsap";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './testamonial-carousel.css';


export default function TestamonialCarousel() {

    // function selectItem(index) {
    //     switch (index) {
    //         case 0:
    //             gsap.to(".design", { opacity: 0, duration: 0.5 });
    //             break;
    //         case 1:
    //             gsap.set(".design", { x: 0, opacity: 1 })
    //             gsap.from(".design", { opacity: 0, xPercent: 100, duration: 0.75, delay: 0, ease: "quad.inout" });
    //             break;
    //         case 2:
    //             gsap.set(".key-art", { x: 0, opacity: 1 });
    //             gsap.from(".key-art", { opacity: 0, xPercent: 100, duration: 0.75, delay: 0, ease: "quad.inout" });
    //             gsap.to(".design", { opacity: 0, duration: 0.5 });
    //             break;
    //     }

    // }

    return (

        <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={false}
            interval={5000}
            // onChange={selectItem}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={75}


        >

            <div className="testamonial-slide">
                <div className="slide-inner">
                    <div className='testamonial-copy'>
                        <h1>Eric Sams</h1>
                        <h2>Creative Director, JustPremium / GumGum</h2>
                        <p>"Adam is both an accomplished designer and developer. Whether
                            concepting new ad campaign ideas for industry-leading international
                            brands, or delving into advanced animation techniques using
                            JavaScript, his considered approach helps us win new accounts and
                            ensure our current client base keeps returning for repeat business. I
                            feel confident Adam’s broad range of skills and keen aesthetic acumen
                            will add value to your team, project or new venture."</p>
                    </div>
                </div>
            </div>

            <div className="testamonial-slide">
                <div className="slide-inner">
                    <div className='testamonial-copy'>
                        <h1>Martyn Jones</h1>
                        <h2>Head of Product, If You Can</h2>
                        <p>"Adam was a perfect fit for our agile game development outfit. As a Product Manager you hope for people in your delivery team to be multi-disciplinary, proactive problem solvers and conscientious workers. Adam has all of this in spades and in addition is a pleasure to work with. He offered consistently solid technical implementations and has a keen eye for user centred design and gameplay design."</p>
                    </div>
                </div>
            </div>

            <div className="testamonial-slide">
                <div className="slide-inner">
                    <div className='testamonial-copy'>
                        <h1>Jeff Ortegon</h1>
                        <h2>VP, Global Head of Design at Publicis Media</h2>

                        <p>"Throughout my time working with Adam Cousins he has proven to be the most creative and capable web developer and designer I've ever had the pleasure of working with. He had a fantastic eye for unique designs and was able to implement some of the best strategies our company had."</p>
                    </div>
                </div>
            </div>

            <div className="testamonial-slide">
                <div className="slide-inner">
                    <div className='testamonial-copy'>
                        <h1>France Jie Linn</h1>
                        <h2>Senior Creative Project Manager, GumGum</h2>
                        <p>"I worked with Adam for about a year. When it comes to creativity, he is one of the best. He is the master of animation, design and photoshop. I am always amazed on how he turns something very simple into masterpieces. He is also very clear on communication and a great team player. If you ever have him on your team, lucky you!"</p>
                    </div>
                </div>
            </div>

            <div className="testamonial-slide">
                <div className="slide-inner">
                    <div className='testamonial-copy'>
                        <h1>Sophia Van Rosmalen</h1>
                        <h2>Creative Project Manager, GumGum</h2>

                        <p>"Adam is a phenomenal Designer and Developer. I had worked with him on a daily basis at GumGum where he was a Senior Creative Designer.
                            He produced some superb ads which were highly praised by clients. He demonstrated good problem solving skills, was never shy of a challenge and always keen to elevate his designs to give users a unique experience.
                            In addition, I felt I could always rely on his expert opinion on complex briefings where he would clearly provide a solution.
                            Adam is driven and dedicated, I would highly recommend him to anyone for a Senior Dev/Des role."</p>
                    </div>
                </div>
            </div>



            <div className="testamonial-slide">
                <div className="slide-inner">
                    <div className='testamonial-copy'>
                        <h1>Simon Lerpiniere</h1>
                        <h2>Lead Software Architect, Mindstorm Multitouch</h2>
                        <p>"Adam is a fast, efficient developer with an uncanny ability to pick-up new technology. Always professional to work with, Adam has not put a foot wrong the entire time I have worked with him."</p>
                    </div>
                </div>
            </div>



            <div className="testamonial-slide">
                <div className="slide-inner">
                    <div className='testamonial-copy'>
                        <h1>Victoria Behun</h1>
                        <h2>Senior Art Director, Doner</h2>
                        <p>"Adam understands the importance of attention to detail and loves an opportunity to push the boundaries of any platform in order to create something truly interesting and unique."</p>
                    </div>
                </div>
            </div>

            



        </Carousel>
    );
};

