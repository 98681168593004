import { React, useState } from 'react'
import { createPortal } from 'react-dom';

import VideoModal from '../../components/videomodal/VideoModal';
import CTA from '../../components/cta/CTA';
import Logo from '../logo/Logo';
import './navbar.css'





export default function Navbar() {


  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    console.log("toggle modal");
    setShowModal(!showModal);
  }

  

  return (

    <div className="navbar-container">
      {showModal && createPortal(<VideoModal onClose={toggleModal} />, document.querySelector("#root"))}
      <div className="navbar-container-links">

        <Logo />
        <div className='showreel-cta-wrapper' onClick={ toggleModal } >
          <CTA text="Watch Showreel →" />
        </div>

      </div>

    </div>

  )
}


