import React from 'react';
import YoutubeEmbed from "../../../components/youtubeembed/YoutubeEmbed";

import '../workpage.css'

export default function YoutTubeContent ({ data }) {


    return (
        <div className='work-item-section video'>
            <YoutubeEmbed embedId={data} />
        </div>
    );


};

