import React from 'react'
import './cta.css'

const CTA = ({text}) => {
  return (
    <div className='cta-wrapper'>
      <div className='cta-inner'>{text}</div>
    </div>
  )
}

export default CTA
