import { React, useEffect, useLayoutEffect, useRef } from 'react'
import { gsap } from "gsap";
import { Link } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import screen from '../../assets/hero/screen.png';
import video from '../../assets/videos/clips.mp4';
import showreel from '../../assets/videos/showreel.mp4';

import './hero.css';

gsap.registerPlugin(ScrollTrigger);

export default function Hero({ updateNavFunc }) {

  const hero = useRef();

  let dir = 1;

  useEffect(() => {

    let vid = document.getElementById("intro-video-0");

    let mm = gsap.matchMedia();

    let ctx = gsap.context((self) => {

      mm.add("(max-width: 1024px)", () => {
        gsap.set(".video-mover", { x: 740 });
      });



      let navTLInBottom = gsap.timeline({
        defaults: { duration: 1, ease: "none" },
        scrollTrigger: {
          markers: false,
          trigger: hero.current,
          start: 'bottom 50%',
          end: 'bottom 50%',
          scrub: true,
          onUpdate: self => {
            if (self.direction == -1) {
              updateNavFunc("Home");
            }
          }
        }
      });


      let inTL = gsap.timeline({});
      inTL.from(".video-mover", 0.5, { opacity: 0, xPercent: 50, ease: "quad.inout" }, 0.5);
      inTL.from(".circle", 0.5, { scale: 0, ease: "quad.inout" }, 0);

      inTL.add(function () {
        vid.play();
        mm.add("(max-width: 1024px)", () => {

          vid.addEventListener("timeupdate", function (e) {
            if (vid.currentTime > 2 && vid.currentTime < 2.5) {
              gsap.to(".video-mover", 0.25, { x: 0, scale: 0.5, ease: "quad.inout" });
            }
            if (vid.currentTime > 5 && vid.currentTime < 5.5) {
              gsap.to(".video-mover", 0.25, { x: -740, scale: 1, ease: "quad.inout" });
            }
            if (vid.currentTime > 10 && vid.currentTime < 10.5) {
              gsap.to(".video-mover", 0.25, { x: 0, scale: 0.5, ease: "quad.inout" });
            }
            if (vid.currentTime > 16 && vid.currentTime < 16.5) {
              gsap.to(".video-mover", 0.25, { x: 740, scale: 1, ease: "quad.inout" });
            }
            if (vid.currentTime > 18.75 && vid.currentTime < 19.5) {
              gsap.to(".video-mover", 0.25, { x: -740, scale: 1, ease: "quad.inout" });
            }
            if (vid.currentTime > 20.25 && vid.currentTime < 21) {
              gsap.to(".video-mover", 1, { x: 740, ease: "quad.inout", scale: 1 });
            }
          })
        });
      }, 1);

      //console.log(document.querySelector(".hero-wrapper").getBoundingClientRect());
      mm.add("(min-width: 100px)", () => {
        let outTL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: "none"
          },
          scrollTrigger: {
            markers: false,
            trigger: hero.current,
            start: 'bottom 99%',
            end: 'bottom 1%',
            scrub: true
          }
        });
        outTL.to(".circle", { rotateX: -90 }, 0);
        outTL.to(hero.current, { y: "50vh" }, 0);
        //outTL.to(self.selector(".fader"), { opacity: 1 }, 0);
      });

    }, hero);

    return () => ctx.revert();

  }, []);


  return (
    
    <div ref={hero} className='hero-wrapper container' id='home'>
      
      <div className='fader'></div>
      <div className='container-inner'>
        
        <div className="circle centered"></div>
        <video preload="auto" id="showreel" src={showreel} muted loop autoPlay playsInline className='showreel cover centered'></video>
      </div>



      <div className='video-clips-wrapper clips-0 centered contain'>
        <div className='video-mover fullbleed'>
          <div className='scaler fullbleed'>
            <video preload="auto" id="intro-video-0" src={video} muted loop playsInline className='video-clips centered'></video>
            <img src={screen} alt="screen" className="screen" />
          </div>
        </div>

      </div>


      {/* <div className='video-clips-wrapper clips-1 contain'>
        <div className='scaler fullbleed'>
          <video id="intro-video-1" src={video} muted loop className='video-clips centered'></video>
          <img src={screen} alt="screen" className="screen" />
        </div>
      </div> */}



    </div>
  )
}

