import React from 'react';


import '../workpage.css'

export default function VideoContent ({ data }) {

    console.log(data);

    return (
        <div className='work-item-section video'>
           <video src={data} className='inline-video' muted playsInline autoPlay loop></video>
        </div>
    );


};

