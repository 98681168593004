import { React, useEffect, useRef, useState } from 'react'
import { gsap } from "gsap";
import WorkCarousel from '../carousel/WorkCarousel';
import './workmodal.css';

export default function WorkModal({ workItemData, onClose }) {

    const modal = useRef();


    function handleCloseClicked(){
        document.body.style.overflow = 'unset';
        document.documentElement.style.overflow = 'unset';
        onClose();
    }

    let inTL;
    useEffect(() => {

        let ctx = gsap.context((self) => {  
            gsap.set(modal.current, {top:window.top.pageYOffset});
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';

            inTL = gsap.timeline();
            inTL.from(".bg", 0.5, {opacity:0, xPercent:-100}, 0);
            inTL.from(".copy-wrapper", 0.25, {opacity:0, yPercent:50}, 0.5);
            inTL.from(".carousel-root", 0.25, {opacity:0, y:25}, 0.67);
            inTL.from('.client', 0.5, {opacity:0}, 1);


        }, modal);
        return () => ctx.revert();

    }, []);
    return (
        <div ref={modal} className="work-modal-wrapper">
            <button className='close-button' onClick={handleCloseClicked}><p>X</p></button>


            <div className='copy-wrapper'>
                <h2>{workItemData.client}</h2>
                <h3>{workItemData.title}</h3>
                <h4>{workItemData.subtitle}</h4>
            </div>

            <WorkCarousel workName={workItemData.name} length={workItemData.galleryLength} />

            <p className='client'>{workItemData.agency}</p>
            <div className='bg fullbleed'></div>
        </div>
    )
};

