import { React, useEffect, useRef } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import './clients.css';

gsap.registerPlugin(ScrollTrigger);

export default function Clients({updateNavFunc}) {

  const clientArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

  const clients = useRef();

  useEffect(() => {

    let ctx = gsap.context((self) => {

      let offset = window.innerHeight;

      let mm = gsap.matchMedia();
      let inTL;
      let outTL;

      gsap.delayedCall(0.1, function () {

        mm.add("(min-width: 100px)", () => {
          inTL = gsap.timeline({
            defaults: { duration: 1, ease: "none" },
            scrollTrigger: {
              markers: false,
              trigger: clients.current,
              start: 'top 50%',
              end: 'top 5%',
              scrub: true
            }
          });

          inTL.from(self.selector(".circle-small"), { scale: 2, duration: 1 }, 0);
          // inTL.to(self.selector(".fader"), { opacity: 0 }, 0);


          outTL = gsap.timeline({
            defaults: { duration: 1, ease: "none" },
            scrollTrigger: {
              markers: false,
              trigger: clients.current,
              start: 'bottom 100%',
              end: 'bottom 0%',
              scrub: true
            }
          });
          outTL.to(clients.current, { y: "50vh" }, 0);
          outTL.to(self.selector(".circle-small"), { scale: 0, duration: 1 }, 0);
        });
      });


    }, clients);

    return () => ctx.revert();

  }, []);

  return (
    <div ref={clients} className='clients-wrapper container' id="clients">
   
      <div className='container-inner'>
        <div className="centered circle-small"></div>
      </div>
      <h1>Selected <span>Clients</span></h1>
      <div className="client-grid">
        {clientArr.map((item, index) => (
          
          <img className='client' key={index} src={'/client-logos/client-' + item + '.png'} />
          
        ))}
      </div>
    </div>
  )
}

