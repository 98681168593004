import React from 'react';

import '../workpage.css'

export default function GalleryContent ({ data }) {


    return (
        <div className='workitem-section-gallery work-item-section'>
            {data.map((item, index) => (
                <img className='workitem-gallery-img' src={item} key={index} />
            ))}
        </div>
    );


};

