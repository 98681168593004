import React from 'react';
import { Link } from 'react-router-dom';

import './workitem.css';

export default function WorkItem ({ data, modalcallback }) {


    function handleClicked() {
        modalcallback(data);
    }

    return (
        <Link to={"/workitem#" + data.name}>
            <div className="work-item-wrapper">
                <img src={'/workdata/' + data.name + '/cover.jpg'} className='work-img' />
                <div className="work-header fullbleed">
                    <div className='bg fullbleed'></div>
                    <h2>{data.client}</h2>
                    <h1>{data.title}</h1>
                    <p>{data.subtitle}</p>
                </div>
            </div>
        </Link>
    );


};

