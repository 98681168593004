import React from 'react';
import { gsap } from "gsap";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './about-carousel.css';

import phoneframe from '../../../../assets/phone-frame.png';
import design0 from '../../../../assets/about/design-0.png';
import keyart from '../../../../assets/about/key-art.jpg';

import videoclip from '../../../../assets/videos/night-jogger.mp4';
import codeclips from '../../../../assets/videos/ultraboost.mp4';

const CarouselCompnent = () => {

    function selectItem(index) {
        //console.log(index);
        switch (index) {
            case 0:
                gsap.to(".design", { opacity: 0, duration: 0.5 });
                break;
            case 1:
                gsap.set(".design", { x: 0, opacity: 1 })
                gsap.from(".design", { opacity: 0, xPercent: 100, duration: 0.75, delay: 0, ease: "quad.inout" });
                break;
            case 2:

                gsap.to(".design", { opacity: 0, duration: 0.5 });
                break;
            case 3:
                gsap.set(".key-art", { x: 0, opacity: 1 });
                gsap.from(".key-art", { opacity: 0, xPercent: 100, duration: 0.75, delay: 0, ease: "quad.inout" });
                break;
        }
    }

    return (

        <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            onChange={selectItem}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={40}
        >
            <div className="about-slide">
                <div className="slide-inner">
                    <div className='panel copy-panel'>
                        <h1 className='about-header'><span>Experienced<br />creative developer + digital designer</span></h1>
                        <h2 className='about-copy'>I have been crafting premium<br></br>digital experiences for 12+ years.</h2>
                        <p className='about-copy'>I specialise in end-to-end <span>conception, design, motion and creative build</span> of performant cross-device digital creative products and have worked with a diverse variety of global brands across many verticals. </p>
                        <h2 className='about-copy'>Swipe ← to see more.</h2>
                    </div>
                    <div className='panel visual-panel'>
                        <div className='about-clip-wrapper contain centered'>

                            <div className='scaler fullbleed'>
                                {/* <div className='mask centered'> */}
                                <div className='clipper centered'>
                                    <video src={codeclips} muted autoPlay playsInline loop className='about-clip centered'></video>
                                </div>

                                <img src={phoneframe} alt="phone-frame" className="phone-frame" />
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-slide">
                <div className="slide-inner">
                    <div className='panel copy-panel'>
                        <h1 className='about-header'><span>Concepts, UI + UX and </span>storyboards</h1>
                        <p className='about-copy'>For over 5 years, I have been entrusted to translate client briefs into <span>creative and KPI - oriented concepts</span>, leading onto pitch-ready storyboards and production-ready designs.<br /><br />
                            Design executions include anything from fast and accurate campaign asset preparation and re-touching to <span>pixel perfect</span> UI designs.<br/><br/>
                        </p>
                    </div>
                    <div className='panel visual-panel'>
                        <img src={design0} alt="design" className="contain centered design" />
                    </div>
                </div>

            </div>
            <div className="about-slide">

                <div className="slide-inner">
                    <div className='panel copy-panel'>
                        <h1 className='about-header'><span>Creative coding +</span> motion</h1>
                        <p className='about-copy'>
                            I originally come from a Computer Science background, so have a thorough grounding in programming. I am experienced at <span>hand-coding from scratch in several front-end languages</span>, primarily HTML5 / Javascript & CSS3.<br/><br/>
                            My front-end development work over the years has included responsive websites, HTML5 + Javascript games and animations, cross-device responsive rich media digital ads and iOS games.<br/><br/>
                        </p>
                    </div>
                    <div className='panel visual-panel'>
                        <video src={videoclip} muted autoPlay loop playsInline className='coding-clips contain centered'></video>
                    </div>
                </div>

            </div>
            <div className="about-slide">

                <div className="slide-inner">
                    <div className='panel copy-panel'>
                        <h1 className='about-header'><span>Photo-manipulation +</span> compositing</h1>
                        <p className='about-copy'>
                            I have 3+ years experience designing visuals using photo-manipulation and compositing techniques.
                            <br /><br />
                            I have been showcasing my personal work on my Instagram <span>@adamjcuz</span> and have built up a base of <span>22k+</span> followers and have been featured by the <span>official Adobe Photoshop</span> account.
                            <br /><br />
                            I have produced mutliple 2hr+ tutorial videos for the website <span>PhotoshopEasy.com</span> teaching my techniques.
                        </p>
                    </div>
                    <div className='panel visual-panel'>
                        <img src={keyart} alt="key art" className="contain centered key-art" />
                    </div>
                </div>

            </div>

            
        </Carousel>
    );
};

export default CarouselCompnent;