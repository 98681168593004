import React, { useState, useEffect } from 'react'
import {Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import Logo from '../logo/Logo';

import './footer.css'


export default function Footer() {


  return (

    <div className="footer-container">
      <div className='footer-inner'>
      <p>© {new Date().getFullYear()} Adam Cousins</p>

        <Link className="cv-button"
            to="/docs/AdamCousinsSeniorCreativeDeveloper.pdf"
            target="_blank"
          >
            Download CV ▾

        </Link>
      </div>
     

      {/* <p className='spacer'>© 2023 Adam Cousins</p> */}

      {/* <Logo /> */}

      {/* <div className='socials'>
      
        <SocialIcon bgColor="#1be8c3"  url="https://www.artstation.com/cousins" target="_blank" />
        <SocialIcon bgColor="#1be8c3"  url="https://www.instagram.com/adamjcuz/" target="_blank" />
        <SocialIcon bgColor="#1be8c3"  url="https://www.linkedin.com/in/adamjamescousins/" target="_blank" /> 
      </div> */}
    </div>

  )
}


