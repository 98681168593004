import React, { useLayoutEffect, useRef } from 'react'
import { gsap } from "gsap";
import './logo.css'
import logoAsset from '../../assets/logo-new.png';

export default function Logo() {

    const logo = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context((self) => {
            let tl = new gsap.timeline({defaults:{duration:0.6}});
            tl.from(logo.current, {opacity:0}, 0);
            tl.from(".adam", {top:-25}, 0);
            tl.from(".des", {left:-50}, 0);
            tl.from(".mot", {top:25}, 0);
            tl.from(".cod", {left:50}, 0);
        
        }, logo); 
    
        return () => ctx.revert();
          
      }, []);

    return (

        <div ref={logo} className="logo-container">
            <h1 className='adam'>Adam Cousins</h1>
            <h2 className='tagline'>design, motion & code</h2>
        </div>

    )
}


