import { React, useLayoutEffect, useEffect, useRef } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
// import AboutCarousel from './components/carousel/AboutCarousel';
import './testamonials.css';
import TestamonialCarousel from './components/carousel/TestamonialCarousel';

gsap.registerPlugin(ScrollTrigger);

export default function Testamonials({updateNavFunc}) {

  const testamonials = useRef();

  useEffect(() => {

    let ctx = gsap.context((self) => {

      let offset = window.innerHeight;

      let mm = gsap.matchMedia();
      let inTL;
      let outTL;
      
      gsap.delayedCall(0.1, function() {


        let navTLInTop = gsap.timeline({
          defaults:{duration:1, ease:"none"},
          scrollTrigger: {
            markers:false,
            trigger: testamonials.current,
            start: 'top 50%',
            end:'top 50%',
            scrub: false,
            onUpdate: self => {
              if (self.direction == 1)
              {
                updateNavFunc("Testimonials");
              }
            }
          }
        });

        let navTLInBottom = gsap.timeline({
          defaults:{duration:1, ease:"none"},
          scrollTrigger: {
            markers:false,
            trigger: testamonials.current,
            start: 'bottom 50%',
            end:'bottom 50%',
            scrub: false,
            onUpdate: self => {
              if (self.direction == -1)
              {
                updateNavFunc("Testimonials");
              }
            }
          }
        });

        mm.add("(min-width: 100px)", () => {
          inTL = gsap.timeline({
            defaults:{duration:1, ease:"none"},
            scrollTrigger: {
              markers:false,
              trigger: testamonials.current,
              start: 'top 50%',
              end:'top 5%',
              scrub: true
            }
          });

         // inTL.from(self.selector(".circle"), {rotationY:90, duration:1}, 0);
          //inTL.to(self.selector(".fader"), {opacity:0}, 0);
    
    
          outTL = gsap.timeline({
            defaults:{duration:1, ease:"none"},
            scrollTrigger: {
              markers:false,
              trigger: testamonials.current,
              start: 'bottom 95%',
              end:'bottom 0%',
              scrub: true
            }
          });
          outTL.to(testamonials.current, {y:"50vh"}, 0);
          //outTL.to(self.selector(".fader"), {opacity:1}, 0);
        });
      });


    }, testamonials); 

    return () => ctx.revert();
      
  }, []);

  return (
    <div ref={testamonials} className='testamonials-wrapper container' id="testimonials">
      {/* <div className='fader'></div> */}
      
      <h1 className='headline'><span>LinkedIn</span> Testimonials</h1>
      <TestamonialCarousel />
    </div>
  )
}

