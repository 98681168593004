import { React, useEffect, useRef, useState } from 'react'
import { useLocation, Link } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { gsap } from "gsap";

import GalleryContent from './components/GalleryContent';
import YoutTubeContent from './components/YoutTubeContent';
import VideoContent from './components/VideoContent';
import CTA from '../../components/cta/CTA';
import IntroBlockComponent from './components/IntroBlockComponent';
import TextBlockComponent from './components/TextBlockComponent';
import HomeButton from '../../components/homebutton/HomeButton';


import './workpage.css';

import { workData } from "../../constants";

import laptop from "../../assets/laptop.png";
import URLComponent from './components/URLComponent';


gsap.registerPlugin(ScrollTrigger);

const WorkPage = () => {


    const location = useLocation();
    const workpage = useRef();

    const workname = location.hash.replace('#', '');
    console.log(workname);

    let workItemData = workData.find((element) => {
        return element.name === workname;
    });
    // console.log(workItemData);

    let content = [];
    let newContent;

    for (var i = 0; i < workItemData.content.length; i++) {
        switch (workItemData.content[i].type) {
            case "gallery":
                let srcArray = [];
                for (var j = 0; j < workItemData.content[i].length; j++) {
                    let src = '/workdata/' + workItemData.name + '/' + j + '.jpg';
                    srcArray.push(src);
                }

                newContent = {
                    type: "gallery",
                    data: srcArray
                };
                content.push(newContent);

                break;
            case "video":
                newContent = {
                    type: "video",
                    data: workItemData.content[i].embedId
                };
                content.push(newContent);
                break;
            case "inlinevideo":
                let videoPath = '/workdata/' + workItemData.name + '/' +  workItemData.content[i].path;
                newContent = {
                    type: "inlinevideo",
                    data: videoPath
                };
                content.push(newContent);
                break;
            case "url":
                let urlPath = workItemData.content[i].path;
                let captionText = workItemData.content[i].caption;
                newContent = {
                    type: "url",
                    url: urlPath,
                    caption: captionText
                };
                content.push(newContent);
                break;
            case "intro":
                let brief = workItemData.content[i].brief;
                let response = workItemData.content[i].response;
                newContent = {
                    type: "intro",
                    brief: brief,
                    response: response,
                };
                content.push(newContent);
                break;
            case "text":
                let text = workItemData.content[i].text;
                newContent = {
                    type: "text",
                    text: text
                };
                content.push(newContent);
                break;
        }
    }

    
    console.log(content);

    useEffect(() => {

        window.dispatchEvent(new Event('resize'));

        let ctx = gsap.context((self) => {


            let outTL = gsap.timeline({
                defaults: {
                    duration: 1,
                    ease: "none"
                },
                scrollTrigger: {
                    markers: false,
                    trigger: self.selector(".workitem-section-hero"),
                    start: 'bottom 95%',
                    end: 'bottom 5%',
                    scrub: true
                }
            });
            outTL.to(self.selector(".circle"), { rotateY: 90 }, 0);
            outTL.to(self.selector(".workitem-section-hero"), { y: "50vh" }, 0);
            outTL.to(self.selector(".fader"), { opacity: 1 }, 0);

        }, workpage);

        return function () {
            ctx.revert();
        }
    }, []);
    return (
        <div ref={workpage} className='page work-page-wrapper'>


            <Link to="/"><HomeButton /></Link>

            <div className='workitem-section-hero work-item-section'>
                <div className='fader'></div>
                <div className='workitem-hero-inner'>
                    <div className='workitem-copy-wrapper'>
                        <h2>{workItemData.client}</h2>
                        <h1>{workItemData.title}</h1>
                        <p>{workItemData.subtitle}</p>
                    </div>
                    <div className='panel visual-panel'>

                        {workItemData.heroMockup ?
                            <div className='hero-img-wrapper contain centered'>
                                <img className='workitem-hero-img' src={'/workdata/' + workItemData.name + '/0.jpg'} />
                                <img src={laptop} className='laptop-bg' />
                            </div>
                            :
                            <img className='workitem-img centered contain' src={'/workdata/' + workItemData.name + '/' + workItemData.heroFile} />
                        }

                    </div>
                </div>
                <div className='container-inner'>
                    <div className="circle centered"></div>
                </div>

            </div>

            {content.map((item, index) => (
                
                
                item.type == "gallery" ? (
                    <GalleryContent data={item.data} key={index} />
                )
                :
                item.type == "video" ? (
                    <YoutTubeContent data={item.data} key={index} />
                )
                :
                item.type == "inlinevideo" ? (
                    <VideoContent data={item.data} key={index} />
                )
                :
                item.type == "url" ? (
                    <URLComponent data={item} key={index} />
                )
                :
                item.type == "intro" ? (
                    <IntroBlockComponent data={item} key={index} />
                )
                :
                item.type == "text" ? (
                    <TextBlockComponent data={item} key={index} />
                )
                :
                null

            ))}


            <div className='work-item-section cta-section'>
                <Link to="/#work"><CTA text="Back to home" /></Link>
            </div>
        </div>
    )
}

export default WorkPage