import { React, useLayoutEffect, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from "./hooks/ScrollToTop";
import { gsap } from "gsap";


import { HomePage, WorkPage} from './pages';
import { ScrollIcon, Footer } from './components';

import OrientationModal from './components/orientationmodal/OrientationModal';


import './App.css';


// gsap.registerPlugin(ScrollToPlugin);

const App = () => {

  const app = useRef();



  const screenSize = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  function designSize() {
    if (screenSize.width < 800) {
      return {
        width: 1080,
        height: 1920
      }
    }
    else if (screenSize.width < 1024) {
      return {
        width: 1200,
        height: 1600
      }
    }
    else {
      return {
        width: 3840,
        height: 2160
      }
    }
  }

  const [showModal, setShowModal] = useState(false);


  useEffect(() => {


    //gsap.set(".container", {height:"+=6vh"});
    gsap.config({
      force3D: true,
      nullTargetWarn: false,
    });


    window.removeEventListener("resize", () => {
      screenSize.width = window.innerWidth;
      screenSize.height = window.innerHeight;
    });

    return () => {
      window.removeEventListener("resize", () => {
        screenSize.width = window.innerWidth;
        screenSize.height = window.innerHeight;
      });
    }
  }, []);

  useLayoutEffect(() => {

    let ctx = gsap.context(() => {

      window.addEventListener("resize", () => {
        
        let mm = gsap.matchMedia();
        mm.add("(max-width: 1024px)", () => {

          if (window.innerWidth > window.innerHeight)
          {
            setShowModal(true);
          }
          else
          {
            setShowModal(false);
              document.body.style.overflow = 'unset';
              document.documentElement.style.overflow = 'unset';
          }
        });
      
        resizeElements();
      });

      function resizeElements() {
        console.log("app resize");

        gsap.set(".centered", { xPercent: -50, yPercent: -50 });
        gsap.set(".centered-x", { xPercent: -50 });
        gsap.set(".centered-y", { yPercent: -50 });

        screenSize.width = window.innerWidth;
        screenSize.height = window.innerHeight;


        var rh = screenSize.height / designSize().height;
        var rw = screenSize.width / designSize().width;

        var scaleFactor = 1;

        var containElements = document.getElementsByClassName("contain");
    
        for (var i = 0; i < containElements.length; i++) {
          var tw = parseInt(containElements[i].dataset.tw);

          var newRW = isNaN(tw) ? rw : designSize().width / tw;
          var newRH = containElements[i].dataset.th != undefined ? designSize().height / containElements[i].dataset.th : rh;
          var minS = containElements[i].dataset.ms != undefined ? containElements[i].dataset.ms : 0;

          scaleFactor = Math.min(0.5, Math.min(newRW, newRH));
          scaleFactor = Math.max(minS, scaleFactor);

          gsap.set(containElements[i], { scale: scaleFactor });
        };

        var coverElements = document.getElementsByClassName("cover");
        for (var j = 0; j < coverElements.length; j++) {
          scaleFactor = Math.max(rh, rw);
          gsap.set(coverElements[j], { scale: scaleFactor });
        };

        var coverWidthElements = document.getElementsByClassName("cover-width");
        for (var k = 0; k < coverWidthElements.length; k++) {
          scaleFactor = Math.max(0.8, rw);
          gsap.set(coverWidthElements[k], { scale: scaleFactor });
        };

        var containWidthElements = document.getElementsByClassName("contain-width");
        for (var l = 0; l < containWidthElements.length; l++) {
          scaleFactor = Math.min(1, rw);
          gsap.set(containWidthElements[l], { scale: scaleFactor });
        };
      }

      resizeElements();

      //console.log(document.querySelectorAll(".centered"));
      gsap.set(".centered", { xPercent: -50, yPercent: -50 });
      gsap.set(".centered-x", { xPercent: -50 });
      gsap.set(".centered-y", { yPercent: -50 });

    }, app);

    return () => ctx.revert();

  }, []);






  return (

    <Router>
    <ScrollToTop />
    <div ref={app} className="App">


      {showModal && createPortal(<OrientationModal />, document.body)}
        

      <ScrollIcon />
      

      <Switch>
        
        <Route exact path="/workitem">
          <WorkPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
 
      
      <Footer />
    </div>
    </Router>
  )
}

export default App
