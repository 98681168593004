import { React, useEffect, useRef } from 'react'
import { gsap } from "gsap";
import './videomodal.css';

import CloseIcon from '../../assets/close.svg'
import showreel from '../../assets/videos/showreel.mp4';

export default function VideoModal({ onClose }) {

    const videomodal = useRef();
    

    let inTL;

    function closeModal() {
        document.body.style.overflow = 'visible';
        document.documentElement.style.overflow = 'visible';
        onClose();
    }

    useEffect(() => {

        let ctx = gsap.context((self) => {  
            inTL = gsap.timeline();
            inTL.add(function(){
                gsap.set(videomodal.current, {top:window.top.pageYOffset});
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
            }, 0.01);
        }, videomodal);
        return () => ctx.revert();

    }, []);
    return (
        <div ref={videomodal} className="video-modal-wrapper">
            <button onClick={closeModal} className='close-button'>
                <img src={CloseIcon} className='close-icon' />
            </button>

           
            <video preload="auto" id="showreel" src={showreel} muted controls loop autoPlay playsInline className='showreel'></video>
            
            <div className='bg'></div>
        </div>
    )
};

