import { React, useLayoutEffect, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import WorkItem from './components/workitem/WorkItem';
import WorkModal from './components/modal/WorkModal';
import './work.css';

import { workData } from "../../constants";


export default function Work({updateNavFunc}) {
  const work = useRef();

  let currentY = 0;

  // useLayoutEffect(() => {
  //   //console.log("USE EFFECT");
  //   return () => {};
  // }, []);

  let mm = gsap.matchMedia();
  
  useEffect(() => {

    //gsap.set(work.current, {y:"-=6vh"});

    let ctx = gsap.context((self) => {

      
      

      let inTL;
      let outTL;
      gsap.delayedCall(0.01, function() {


        let navTLInTop = gsap.timeline({
          defaults:{duration:1, ease:"none"},
          scrollTrigger: {
            markers:false,
            trigger: work.current,
            start: 'top 50%',
            end:'top 50%',
            scrub: false,
            onUpdate: self => {
              if (self.direction == 1)
              {
                updateNavFunc("Work");
              }
            }
          }
        });

        let navTLInBottom = gsap.timeline({
          defaults:{duration:1, ease:"none"},
          scrollTrigger: {
            markers:false,
            trigger: work.current,
            start: 'bottom 50%',
            end:'bottom 50%',
            scrub: false,
            onUpdate: self => {
              if (self.direction == -1)
              {
                updateNavFunc("Work");
              }
            }
          }
        });



        mm.add("(min-width: 100px)", () => {
          inTL = gsap.timeline({
            defaults:{duration:1, ease:"none"},
            scrollTrigger: {
              markers:false,
              trigger: work.current,
              start: 'top 50%',
              end:'top 1%',
              scrub: true
            }
          });
          
          inTL.from(self.selector(".circle-small"), { scale: 0, duration: 1, opacity:1 }, 0);

          outTL = gsap.timeline({
            defaults:{
              duration:1,
              ease:"none"
            },
            scrollTrigger: {
              markers:false,
              trigger: work.current,
              start: 'bottom 99%',
              end:'bottom 1%',
              scrub: true
            }
          });
          outTL.to(self.selector(".circle-small"), { scale: 0, duration: 1 }, 0);


        });
  
      });
    }, work); 

    return () => ctx.revert();
      
  }, []);


  const [data, setData] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleWorkItemClicked = (data) => {
    setData(data);
    //setShowModal(true);
  }

  return (

      <div ref={work} className='work-wrapper container' id="work">
 
        <h1>Selected <span>Work</span></h1>
        <div className="work-grid">
          {workData.map((item, index) => (
            <WorkItem data={item} key={index} />
          ))}
        </div>
        <div className='container-inner'>
          <div className="centered circle-small"></div>
        </div>
        {/* {showModal && createPortal(
          <WorkModal workItemData={data} onClose={() => setShowModal(false)} />,
          document.body
        )} */}
      </div>

  )
}

