import React from 'react'
import './homebutton.css'
import HomeIcon from '../../assets/home.svg';

const HomeButton = ({text}) => {
  return (
    <div className='home-button-wrapper'>
      <img src={HomeIcon} className='home-icon' />
    </div>
  )
}

export default HomeButton
