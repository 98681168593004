import { React, useLayoutEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { useLocation, Link } from 'react-router-dom';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import './navstrip.css'
import { navLinks } from "../../constants";

import { createPortal } from 'react-dom';

import VideoModal from '../../components/videomodal/VideoModal';


gsap.registerPlugin(ScrollToPlugin);

const NavStrip = forwardRef((props, ref) => {

  const [active, setActive] = useState();

  const location = useLocation();

  const navstrip = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    console.log("toggle modal");
    setShowModal(!showModal);
  }


  useLayoutEffect(() => {

    setActive("Home");

    let ctx = gsap.context((self) => {

      let mm = gsap.matchMedia();
      mm.add("(min-width: 1024px)", () => {
        let outTL = gsap.timeline({
          defaults: {
            duration: 1,
            ease: "none"
          },
          scrollTrigger: {
            markers: false,
            trigger: navstrip.current,
            start: 'bottom 1%',
            end: 'bottom 0%',
            scrub: true,
            onUpdate: self => {
              if (self.direction == 1)
              {
                navstrip.current.classList.add("nav-fixed");
              }
              else
              { 
                navstrip.current.classList.remove("nav-fixed");
              }
            }
          }
        });
      });



      // scroll to section on load
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

    }, navstrip);

    return function () {
      window.removeEventListener("scroll", () => { });
      ctx.revert();
    }

  }, []);

  function handleHamburgerClicked() {
    if (isOpen) {
      setIsOpen(false);
      gsap.set(".nav-buttons", { autoAlpha:0});
      gsap.to(".line-0", 0.2, { rotation: 0, y: 0});
      gsap.to(".line-1", 0.2, { rotation: -0, y: 0});
      
      gsap.set(".nav-bg", { autoAlpha:0});
    }
    else {
      setIsOpen(true);
      gsap.set(".nav-buttons", { autoAlpha:1});

      gsap.to(".line-0", 0.2, { rotation: 45, y: "0.34rem" });
      gsap.to(".line-1", 0.2, { rotation: -45, y: "-0.34rem" });

      gsap.set(".nav-bg", { autoAlpha:0.9});
    }
  }



  function handleNavButtonClicked(e, title)
  {
    console.log(title);
    
    if (title === "Showreel"){
      e.preventDefault();
      toggleModal();
    }
    else{
      setActive(title);
    }

    


    let mm = gsap.matchMedia();
    mm.add("(max-width: 1023px)", () => {
      handleHamburgerClicked();
    });
  }

  useImperativeHandle(ref, () => ({
    updateNavFromParent(to) {
      setActive(to)
    }
  }));
  return (
    <div ref={navstrip} className='navstrip-parent'>

      {showModal && createPortal(<VideoModal onClose={toggleModal} />, document.querySelector("#root"))}
   
      <div className="navstrip-container">

        <div className='nav-bg'></div>

        <div className='hamburger-nav-wrapper' onClick={handleHamburgerClicked}>
          <div className='line line-0'></div>
          <div className='line line-1'></div>
        </div>

        <ul className='nav-buttons fullbleed'>
          {navLinks.map((nav) => (

            <a key={nav.id} href={`${nav.id}`}>
            <li
              className={`${active === nav.title ? "active" : "inactive"}`}
              onClick={(e) => handleNavButtonClicked(e, nav.title)}
            >
              {nav.title}
            </li>
            </a>
            
          ))}
        </ul>
      </div>
    </div>
  )
})
export default NavStrip

