import React from 'react';


import '../workpage.css'
import CTA from '../../../components/cta/CTA';

export default function URLComponent ({ data }) {

    console.log(data);

    return (
        <div className='work-item-section url'>
            <a href={data.url} target="_blank">
                <CTA text={data.caption} />
            </a>
           
        </div>
    );


};

