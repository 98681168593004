import { React, useEffect, useRef } from 'react'
import { gsap } from "gsap";
import './orientationmodal.css';

export default function OrientationkModal({ onClose }) {

    const orientationmodal = useRef();

    let inTL;
    useEffect(() => {

        let ctx = gsap.context((self) => {  
            inTL = gsap.timeline();
            inTL.add(function(){
                gsap.set(orientationmodal.current, {top:window.top.pageYOffset});
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
            }, 0.01);
        }, orientationmodal);
        return () => ctx.revert();

    }, []);
    return (
        <div ref={orientationmodal} className="orientation-modal-wrapper">
            {/* <button className='close-button'><p>X</p></button> */}

            <p>Please <span>rotate your device</span></p>
           
            <div className='bg fullbleed'></div>
        </div>
    )
};

