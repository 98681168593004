import { React, useLayoutEffect, useRef } from 'react'
import { gsap } from "gsap";
import './scrollicon.css'

const ScrollIcon = () => {


    const scrollicon = useRef();
    let currentY = 0;


    useLayoutEffect(() => {

        let ctx = gsap.context(() => {

            var tl = gsap.timeline({repeat:-1});
            tl.set(".bar", {top:"15%", opacity:1}, 0);
            tl.to(".bar", {duration:0.9, top:"50%", opacity:0, ease:"quad.inOut"}, 0.1);
            tl.to(".bar", {duration:0.34, top:"15%", opacity:1, ease:"quad.inOut"}, 1);
            

            //gsap.from(".bar", {duration:1, height:0, ease:"back.out", repeat:-1, repeatDelay:1})

        }, scrollicon);

        return () => ctx.revert();

    }, []);

    return (
        <div ref={scrollicon} className='scroll-icon centered-x contain'>
            <div className='scroll-icon-inner fullbleed'>
                <div className='outline'></div>
                <div className='bar'></div>
            </div>
            
        </div>
    )
}

export default ScrollIcon
